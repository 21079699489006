import * as React from 'react';
import Layout from '../components/layout';
import Container from '../components/container';

export default function TermsPage() {
  return (
    <Layout>
      <Container className="max-w-3xl space-y-10">
        <h1 className="mt-20 text-4xl font-bold text-gray-800">Volti Studio Terms of Service</h1>

        <section className="max-w-3xl space-y-4 text-xl text-gray-700">
          <h2 className="text-2xl font-bold text-gray-800">1. Terms</h2>
          <p>
            By accessing the website at https://voltistudio.com, you are agreeing to be bound by
            these terms of service, all applicable laws and regulations, and agree that you are
            responsible for compliance with any applicable local laws. If you do not agree with any
            of these terms, you are prohibited from using or accessing this site. The materials
            contained in this website are protected by applicable copyright and trademark law.
          </p>
        </section>

        <section className="max-w-3xl space-y-4 text-xl text-gray-700">
          <h2 className="text-2xl font-bold text-gray-800">2. Use License</h2>
          <p>
            a. Permission is granted to temporarily download one copy of the materials (information
            or software) on Volti Studio’s website for personal, non-commercial transitory viewing
            only. This is the grant of a license, not a transfer of title, and under this license
            you may not:
          </p>

          <ul className="space-y-3 list-disc list-inside">
            <li>modify or copy the materials;</li>
            <li>
              use the materials for any commercial purpose, or for any public display (commercial or
              non-commercial);
            </li>
            <li>
              attempt to decompile or reverse engineer any software contained on Volti Studio’s
              website;
            </li>
            <li>remove any copyright or other proprietary notations from the materials; or</li>
            <li>
              transfer the materials to another person or “mirror” the materials on any other
              server.
            </li>
          </ul>

          <p>
            b. This license shall automatically terminate if you violate any of these restrictions
            and may be terminated by Volti Studio at any time. Upon terminating your viewing of
            these materials or upon the termination of this license, you must destroy any downloaded
            materials in your possession whether in electronic or printed format.
          </p>
        </section>

        <section className="max-w-3xl space-y-4 text-xl text-gray-700">
          <h2 className="text-2xl font-bold text-gray-800">3. Disclaimer</h2>
          <p>
            a. The materials on Volti Studio’s website are provided on an ‘as is’ basis. Volti
            Studio makes no warranties, expressed or implied, and hereby disclaims and negates all
            other warranties including, without limitation, implied warranties or conditions of
            merchantability, fitness for a particular purpose, or non-infringement of intellectual
            property or other violation of rights.
          </p>
          <p>
            b. Further, Volti Studio does not warrant or make any representations concerning the
            accuracy, likely results, or reliability of the use of the materials on its website or
            otherwise relating to such materials or on any sites linked to this site.
          </p>
        </section>

        <section className="max-w-3xl space-y-4 text-xl text-gray-700">
          <h2 className="text-2xl font-bold text-gray-800">4. Limitations</h2>
          <p>
            In no event shall Volti Studio or its suppliers be liable for any damages (including,
            without limitation, damages for loss of data or profit, or due to business interruption)
            arising out of the use or inability to use the materials on Volti Studio’s website, even
            if Volti Studio or a Volti Studio authorized representative has been notified orally or
            in writing of the possibility of such damage. Because some jurisdictions do not allow
            limitations on implied warranties, or limitations of liability for consequential or
            incidental damages, these limitations may not apply to you.
          </p>
        </section>

        <section className="max-w-3xl space-y-4 text-xl text-gray-700">
          <h2 className="text-2xl font-bold text-gray-800">5. Accuracy of Materials</h2>
          <p>
            The materials appearing on Volti Studio’s website could include technical,
            typographical, or photographic errors. Volti Studio does not warrant that any of the
            materials on its website are accurate, complete or current. Volti Studio may make
            changes to the materials contained on its website at any time without notice. However
            Volti Studio does not make any commitment to update the materials.
          </p>
        </section>

        <section className="max-w-3xl space-y-4 text-xl text-gray-700">
          <h2 className="text-2xl font-bold text-gray-800">6. Links</h2>
          <p>
            Volti Studio has not reviewed all of the sites linked to its website and is not
            responsible for the contents of any such linked site. The inclusion of any link does not
            imply endorsement by Volti Studio of the site. Use of any such linked website is at the
            user’s own risk.
          </p>
        </section>

        <section className="max-w-3xl space-y-4 text-xl text-gray-700">
          <h2 className="text-2xl font-bold text-gray-800">7. Modifications</h2>
          <p>
            Volti Studio may revise these terms of service for its website at any time without
            notice. By using this website you are agreeing to be bound by the then current version
            of these terms of service.
          </p>
        </section>

        <section className="max-w-3xl space-y-4 text-xl text-gray-700">
          <h2 className="text-2xl font-bold text-gray-800">8. Governing Law</h2>
          <p>
            These terms and conditions are governed by and construed in accordance with the laws of
            Wyoming and you irrevocably submit to the exclusive jurisdiction of the courts in that
            State or location.
          </p>
        </section>
      </Container>
    </Layout>
  );
}
